<template>
  <v-autocomplete
    v-model="selectedLanguage"
    :items="languages"
    @change="$emit('changeLanguage', selectedLanguage)"
    item-text="nativeName"
    item-value="code"
    label="Мова"
    return-object
    dense
    outlined
    clearable
    hide-details
  ></v-autocomplete>
</template>

<script>
import GetLanguagesFromISO from '@/mixins/getLanguagesFromISO-639-1'

export default {
  name: 'SelectLanguageFromISO',

  mixins: [GetLanguagesFromISO],

  data: () => ({
    selectedLanguage: null,
  }),

  created() {
    this.getAllLanguages()
  },

  methods: {
    clear() {
      this.selectedLanguage = null
    },
  },
}
</script>

<style scoped></style>
